.result-container {
  text-align: left;
  .btn {
    text-align: center;
  }

  .result-copy {
    font-size: 22px;
    font-weight: 500;
    li {
      font-size: 22px;
      font-weight: normal;
    }
  }

  .result-table {
    margin-top: 20px;
  }

  .retake {
    margin-top: 90px;
  }
}
