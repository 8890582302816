.demographic-container {
  background-color: rgba(61, 106, 179, 0.7);

  .demographic-dropdown {
    width: 40% !important;
  }

  .demographic-form {
    text-align: left;
  }

  .demographic-number {
    width: 80px !important;
  }

  .demographic-questions {
    margin-left: 20px;
  }

  .demographic-radio {
    width: 1em;
    height: 1em;
    margin-right: 0;
    margin-bottom: 0;
    box-sizing: border-box;
    padding-top: 20px;
  }

  .demographic-text {
    font-weight: 400;
    font-size: 18px;
    font-style: normal;
    text-decoration: none;
    p {
      margin-left: 5px;
      display: inline-block;
    }
  }

  .other-input {
    // Override block from form-group
    display: inline !important;
    margin-left: 10px;
  }

  .required-info-copy {
    color: rgba(255, 0, 0, 0.62);
  }
}

.dropdownDemo {
  margin-bottom: 1rem !important;
}
