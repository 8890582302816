.tooltip-viz {
  position: absolute;
  text-align: center;
  padding: 0.2rem;
  background: #ffffff;
  color: #313639;
  border: 1px solid #313639;
  border-radius: 4px;
  pointer-events: none;
  font-size: 0.8rem;
}

.hover {
  fill-opacity: 0.5;
}
