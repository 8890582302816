$black: rgba(0, 0, 0, 0.87);
@import './styles/_demographic.scss';
@import './styles/_result.scss';
@import './styles/_header.scss';
@import './styles/_questions.scss';
@import './styles/_data_viz.scss';

.App {
  text-align: center;
  background-color: #ffffff;
  scroll-behavior: smooth;
}

.about-feel-pic {
  padding: 100px;
  max-height: 100%;
  max-width: 100%;
}

.main-feel-pic {
  margin-top: 20px;
}

.about-container {
  text-align: left;

  h1 {
    text-align: center;
  }
  li {
    font-size: 20px;
  }
}

.feel-footer {
  padding: 100px 0 0 0;
}

.bg0 {
  background-color: rgba(106, 193, 237, 0.7);
}

.bg1 {
  background-color: rgba(148, 201, 100, 0.7);
}

.bg2 {
  background-color: rgba(61, 106, 179, 0.7);
}

.bg3 {
  background-color: rgba(158, 53, 148, 0.7);
}

.light-bg0 {
  background-color: rgba(173, 216, 230, 0.51);
}

.light-bg1 {
  background-color: rgba(185, 238, 180, 0.49);
}

.light-bg2 {
  background-color: rgba(106, 151, 238, 0.49);
}

.light-bg3 {
  background-color: rgba(169, 154, 219, 0.48);
}

img {
  max-height: 100%;
  max-width: 100%;
}

.titleInfo {
  text-align: left;
}

.progress-container {
  background-color: #f5f5f5;
  position: sticky;
  width: 100%;
  top: 0;
}

.quiz-container {
  background-color: white;
  top: 0;
  padding-top: 5px;
  padding-bottom: 60px;
}

.quiz-button-container {
  padding-top: 40px;
}
