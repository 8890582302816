$green_decision: #56be8a;
$red_decision: #73394d;
$middle_decision: #9b9faa;

.decision {
  align-items: center;
  display: flex;
  justify-content: center;
  margin: 60px 0 30px;

  -webkit-box-align: center;
  -ms-flex-align: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;

  @media (min-width: 770px) {
    .choice-copy {
      display: none;
    }
    @media (min-width: 991px) {
      .choice-copy {
        display: block;
      }
    }
    .options {
      align-items: center;
      display: flex;
      flex: 0 0 600px;
      justify-content: space-between;
      margin: 0 60px;

      .option {
        border-radius: 50%;
        cursor: pointer;
        transition: all 0.1s ease-in-out;
        display: flex;
        align-items: center;
        justify-content: center;
      }

      .max {
        flex: 0 0 70px;
        max-width: 70px;
        height: 70px;
      }

      .med {
        flex: 0 0 55px;
        max-width: 55px;
        height: 55px;
      }

      .min {
        flex: 0 0 45px;
        max-width: 45px;
        height: 45px;
      }

      .middle {
        border: 2px solid $middle_decision;
        flex: 0 0 45px;
        max-width: 45px;
        height: 45px;
        &:hover {
          background-color: $middle_decision;
        }
      }

      .option.always:hover {
        background-color: $green_decision;
      }

      .option.never:hover {
        background-color: $red_decision;
      }

      .option.never {
        border: 2px solid $red_decision;
      }

      .option.never.selected {
        background-color: $red_decision;
      }

      .option.always.selected {
        background-color: $green_decision;
      }
      .option.middle.selected {
        background-color: $middle_decision;
      }

      .option.always {
        border: 2px solid $green_decision;
      }

      .always {
        color: $green_decision;
      }

      .never {
        color: $red_decision;
      }
    }
  }
  @media (max-width: 770px) {
    .choice-copy {
      display: none;
    }
    .options {
      flex: 0 0 100%;
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      align-items: center;
      justify-content: space-around;

      .option {
        border-radius: 50%;
        cursor: pointer;
        -webkit-transition: all 0.1s ease-in-out;
        transition: all 0.1s ease-in-out;
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-align: center;
        -ms-flex-align: center;
        align-items: center;
        -webkit-box-pack: center;
        -ms-flex-pack: center;
        justify-content: center;
        border-width: 3px;
        -webkit-box-direction: normal;
      }

      .max {
        -webkit-box-flex: 0;
        -ms-flex: 0 0 70px;
        flex: 0 0 45px;
        max-width: 45px;
        height: 45px;
      }

      .med {
        -webkit-box-flex: 0;
        -ms-flex: 0 0 27px;
        flex: 0 0 27px;
        max-width: 27px;
        height: 27px;
      }

      .min {
        -webkit-box-flex: 0;
        -ms-flex: 0 0 22px;
        flex: 0 0 22px;
        max-width: 22px;
        height: 22px;
      }

      .middle {
        border: 2px solid $middle_decision;
        -webkit-box-flex: 0;
        -ms-flex: 0 0 22px;
        flex: 0 0 22px;
        max-width: 22px;
        height: 22px;
        &:hover {
          background-color: $middle_decision;
        }
      }

      .option.always:hover {
        background-color: $green_decision;
      }

      .option.never:hover {
        background-color: $red_decision;
      }

      .option.never {
        border: 2px solid $red_decision;
      }

      .option.never.selected {
        background-color: $red_decision;
      }

      .option.always.selected {
        background-color: $green_decision;
      }
      .option.middle.selected {
        background-color: $middle_decision;
      }

      .option.always {
        border: 2px solid $green_decision;
      }

      .always {
        color: $green_decision;
      }

      .never {
        color: $red_decision;
      }
    }
  }
}

.question-copy {
  align-self: center;
  color: #576071;
  display: flex;
  font-weight: 600;
  line-height: 1.5;
  margin-left: auto;
  margin-right: auto;
  max-width: 900px;
  text-align: center;
}

.option-copies {
  margin-top: 0 !important;
  .option-copy {
    display: flex;
    align-items: center;
    justify-content: center;
    // override to align with input items
    width: 45px;
  }
}
